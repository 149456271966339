import { FormEvent, useState } from 'react';

import { client } from '../api';

export function Login() {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const response = await client.post('/auth/login', {
      email,
      password,
    });
    if (response.status === 200) {
      localStorage.setItem('officeToken', response.data);
      window.location.href = '/';
      setLoading(false);
    } else if (response.status === 403) {
      setError(true);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }
  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 px-12">
        <div className="mt-20 mx-auto w-full max-w-sm">
          <h2 className="my-6">fern office</h2>
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(event) => setEmail(event.target.value)}
                  className={`block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-neutral-40 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-100 ${
                    error ? 'ring-2 ring-red-500' : ''
                  }`}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(event) => setPassword(event.target.value)}
                  className={`block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-neutral-40 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-100 ${
                    error ? 'ring-2 ring-red-500' : ''
                  }`}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className={`disabled:bg-neutral-40 disabled:border-neutral-40 font-bold disabled:cursor-not-allowed disabled:hover:brightness-100
                bg-primary-100 rounded-full text-white hover:brightness-90 px-7 h-[3.25rem] text-base`}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
