"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
require("./checkbox.css");
function Checkbox(props) {
    const { error, id, select, checked, label, size = '6', labelStyles, disabled, } = props;
    const smallCheckbox = size === '5';
    return ((0, jsx_runtime_1.jsx)("div", { id: id, className: `flex h-${size} w-full`, children: (0, jsx_runtime_1.jsxs)("label", { className: "flex", children: [(0, jsx_runtime_1.jsx)("input", { id: "check", type: "checkbox", name: "checkbox", className: `w-${size} h-${size} rounded
          ${error ? 'border-error' : ''} my-0 accent-primary-100 checked:before:bg-primary-100 
          ${smallCheckbox
                        ? 'checked:before:pl-[0.085rem] checked:before:pt-[0.05rem]'
                        : 'checked:before:pl-[0.1875rem] checked:before:pt-[0.1875rem]'}
          checked:before:rounded checked:border-primary-100 before:bg-neutral-40 before:rounded before:border-neutral-40 border-0 hover:cursor-pointer peer`, value: id, checked: checked, onChange: select, disabled: disabled }), (0, jsx_runtime_1.jsx)("svg", { className: `invisible peer-checked:visible hover:cursor-pointer 
          ${smallCheckbox ? 'mt-[1px] ml-[-19px]' : 'mt-[3px] ml-[-21px]'} `, width: "18", height: "18", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M4.91967 11.6403L10.1697 17.7653L18.15 6.675", stroke: "#ffffff", strokeWidth: "3" }) }), (0, jsx_runtime_1.jsx)("span", { className: `ml-8 items-center absolute text-sm !leading-6 text-neutral-100 hover:cursor-pointer w-auto inline ${checked && 'font-bold leading'} ${labelStyles}`, children: label })] }) }));
}
exports.default = Checkbox;
