"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
require("./radio.css");
function Radio(props) {
    const { error, id, select, checked, unCheck, label } = props;
    const unCheckRadio = () => {
        if (checked) {
            unCheck && unCheck();
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex items-center h-6 w-full", onClick: () => unCheckRadio(), children: (0, jsx_runtime_1.jsxs)("label", { className: "flex", children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", name: "radio", className: `border-neutral-40 w-6 h-6 ${error ? 'border-error' : ''} focus:border my-0 checked:before:bg-primary-100 checked:border-primary-100 checked:before:pl-[0.1875rem] checked:before:pt-[0.1875rem] hover:cursor-pointer peer`, id: id, value: id, checked: checked, onChange: select }), (0, jsx_runtime_1.jsx)("svg", { className: `invisible peer-checked:visible mt-[3px] ml-[-21px]
           hover:cursor-pointer`, width: "18", height: "18", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M4.91967 11.6403L10.1697 17.7653L18.15 6.675", stroke: "#ffffff", strokeWidth: "3" }) }), label && ((0, jsx_runtime_1.jsx)("span", { className: `ml-8 items-center absolute text-sm !leading-6 text-neutral-100 hover:cursor-pointer w-auto inline ${checked && 'font-bold leading'}`, children: label }))] }) }));
}
exports.Radio = Radio;
