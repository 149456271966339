"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const currency_1 = require("../../../helpers/currency/currency");
const keywordApprovals_1 = require("../KeywordApprovalTable/keywordApprovals");
function useTableRow(columnIndex, preset, isFirstRow, isLastRow, isEmailResults, currencyCode, showCurrency, value, result, noDestinationCampaign, selectedResults, keywordApprovals, isKeywordHarvesting) {
    const isFirstCell = columnIndex === 0;
    const isLastCell = columnIndex + 1 === preset.length;
    const isSearchTermCell = value === 'searchTerm';
    const isCellHighlighted = isLastCell && !isEmailResults && !isKeywordHarvesting;
    const isMarketplace = value === 'countryCode';
    const isSearchTerm = value === 'searchTerm';
    const keywordApproved = keywordApprovals &&
        !!keywordApprovals.find((approval) => {
            return (0, keywordApprovals_1.resultMatch)(result, approval) && approval.status === 'APPROVED';
        });
    const keywordRejected = keywordApprovals &&
        !!keywordApprovals.find((approval) => {
            return (0, keywordApprovals_1.resultMatch)(result, approval) && approval.status === 'REJECTED';
        });
    const resultSelected = selectedResults &&
        selectedResults.find((selectedResult) => {
            return (0, keywordApprovals_1.resultMatch)(result, selectedResult);
        });
    let cellData;
    if (value === 'currentBid') {
        cellData =
            result[value] ||
                result['previousBid'];
    }
    else if (value === 'simulatedBid') {
        cellData =
            result[value] ||
                result['adjustedBid'];
    }
    else {
        cellData = result[value];
    }
    const cellStyle = `
    ${isFirstCell && 'border-l'}
    ${isLastCell && `border-r`}
    ${isFirstRow && `border-t border-b`}
    ${isFirstRow && `${isFirstCell && 'rounded-tl-md'} `}
    ${isFirstRow && `${isLastCell && 'rounded-tr-md !border-r'}`}
    ${isLastRow && `${isFirstCell && 'rounded-bl-md'}`}
    ${isLastRow && `${isLastCell && 'rounded-br-md'}`}
    ${isCellHighlighted && `border-l border-secondary-40 bg-primary-40`}
    ${isSearchTermCell && `border-x border-secondary-40 bg-primary-40`}
    ${isFirstRow && !isSearchTerm && 'border-r-0 border-t'} 
    ${isSearchTerm && keywordApproved && 'italic text-secondary-100'}
    ${isSearchTerm && keywordRejected && 'italic line-through text-neutral-80'}
    ${isMarketplace && 'max-w-[3.25rem] flex-auto'}
    ${noDestinationCampaign && 'text-neutral-80 italic'}`;
    const resultCurrency = currencyCode && showCurrency ? currencyCode : '';
    const brandCurrency = currencyCode &&
        showCurrency &&
        typeof cellData === 'string' &&
        cellData &&
        (0, currency_1.formatCurrency)(parseFloat(cellData), resultCurrency, 2);
    function numberFormatting(cellData) {
        if (cellData === 0) {
            return '';
        }
        if (typeof cellData === 'number') {
            return new Intl.NumberFormat().format(cellData);
        }
        if (brandCurrency) {
            return;
        }
        return cellData;
    }
    return {
        cellStyle,
        numberFormatting,
        brandCurrency,
        cellData,
        keywordApproved,
        keywordRejected,
        resultSelected,
    };
}
exports.default = useTableRow;
