"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COUNTRY_URLS = void 0;
exports.COUNTRY_URLS = {
    // germany
    de: 'de',
    // spain
    es: 'es',
    // france
    fr: 'fr',
    // italy
    it: 'it',
    // united kingdom
    uk: 'co.uk',
    // united states
    us: 'com',
    // canada
    ca: 'ca',
    // netherlands
    nl: 'nl',
    // sweden
    se: 'se',
    // turkey
    tr: 'com.tr',
    // poland
    pl: 'pl',
    // denmark
    dk: 'dk',
    // norway
    no: 'no',
    // finland
    fi: 'fi',
    // czech republic
    cz: 'cz',
    // austria
    at: 'at',
    // switzerland
    ch: 'ch',
    // belgium
    be: 'be',
    // ireland
    ie: 'ie',
    // portugal
    pt: 'pt',
    // hungary
    hu: 'hu',
    // greece
    gr: 'gr',
    //mexico
    mx: 'com.mx',
    // brazil
    br: 'com.br',
    // australia
    au: 'com.au',
    // india
    in: 'in',
    // japan
    jp: 'co.jp',
    // singapore
    sg: 'sg',
    // united arab emirates
    ae: 'ae',
};
