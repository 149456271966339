"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPreset = void 0;
const runs_1 = require("../../constants/runs");
const actions_1 = require("../../constants/actions");
const actionMetric = (isBidOrBudget, isPauseOrEnable, isKeywordHarvesting, action) => {
    if (isBidOrBudget) {
        const bidAction = action;
        return bidAction.properties.metric;
    }
    if (isPauseOrEnable) {
        return actions_1.CHANGE_STATUS;
    }
    if (isKeywordHarvesting) {
        return actions_1.HARVEST_KEYWORDS;
    }
    if (action.type === actions_1.SEND_EMAIL) {
        return actions_1.SEND_EMAIL;
    }
    throw Error(`No handler for: ${{ isBidOrBudget, isPauseOrEnable, isKeywordHarvesting, action, actionType: action.type }}`);
};
const getPreset = (dataType, showExtended, isBidOrBudget, isPauseOrEnable, isKeywordHarvesting, action) => {
    if (!dataType) {
        return null;
    }
    // get preset for data type
    const dataTypePreset = showExtended
        ? runs_1.EXTENDED_PRESETS[dataType]
        : runs_1.DEFAULT_PRESETS[dataType];
    if (!dataTypePreset) {
        return null;
    }
    // get preset for action
    const actionPreset = runs_1.ACTION_PRESETS[actionMetric(isBidOrBudget, isPauseOrEnable, isKeywordHarvesting, action)];
    const extendedActionPreset = showExtended &&
        runs_1.EXTENDED_ACTION_PRESETS[actionMetric(isBidOrBudget, isPauseOrEnable, isKeywordHarvesting, action)];
    // filter out any columns that are already in the action preset
    const filteredDataTypePreset = dataTypePreset.filter((presetColumn) => {
        const found = actionPreset &&
            actionPreset.find((column) => column.value === presetColumn.value);
        if (found) {
            return false;
        }
        return true;
    });
    if (!actionPreset) {
        return filteredDataTypePreset;
    }
    const actionPresets = extendedActionPreset
        ? [...extendedActionPreset, ...actionPreset]
        : actionPreset;
    // should always have an action, but might not always have a preset
    // add actions to end of preset
    return [...filteredDataTypePreset, ...actionPresets];
};
exports.getPreset = getPreset;
