import { Step, User, Result, Rule } from '@fern/types';
import StepSummary from '@fern/ui/components/StepSummary/StepSummary';
import { useEffect, useState } from 'react';
import { client } from '../api';
import Results from '@fern/ui/components/Results/Results';
import * as Sentry from '@sentry/react';

export interface StepProps {
  step: Step;
  rule: Rule;
  userId: string;
  ruleId: string;
  stepId: number;
  user: User;
  index: number;
  isLive: boolean;
  isRuleValid: boolean;
}

type PreviewResponse = {
  id: string;
  results: Array<Result>;
};

export function StepDetails({
  step,
  rule,
  userId,
  ruleId,
  stepId,
  user,
  index,
  isLive,
  isRuleValid,
}: StepProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<Array<Result>>([]);

  useEffect(() => {
    async function getResults() {
      if (!isRuleValid) {
        setLoading(false);
        return;
      }
      try {
        const { results } = (
          await client.get(
            `/users/${userId}/rules/${ruleId}/steps/${stepId}/preview`,
          )
        ).data as PreviewResponse;
        setResults(results);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setLoading(false);
      }
    }
    getResults();
  }, [userId, ruleId, stepId, isRuleValid]);

  return (
    <>
      <div
        key={index}
        className="border-neutral-40 mr-10 mr-6 mt-6 rounded-lg border bg-white"
      >
        <div className="bg-secondary-20 flex justify-between rounded-t-lg px-6 py-4 ">
          <h4 className="font-bold">{step.name || `Step ${index + 1}`}</h4>
        </div>
        <div className="mt-6 px-6 ">
          {user && (
            <StepSummary
              step={step}
              userEmail={user.email}
              dataType={rule.dataType}
            />
          )}
        </div>

        {!isRuleValid && (
          <div className="px-6 py-4">
            <h5 className="font-bold">
              This Rule is incomplete - unable to preview results
            </h5>
          </div>
        )}
        {isRuleValid && (
          <Results
            step={{ ...step, results }}
            dataType={rule.dataType}
            dataLoaded={!loading}
            loading={loading}
            isLive={isLive}
          />
        )}
      </div>
    </>
  );
}
