"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const helpers_1 = require("../ResultsTable/helpers");
function TableHeaders({ preset, enableKeywordApproval, isEmailResults, isKeywordHarvesting, extended, }) {
    function renderHeaders() {
        return preset.map((header, index) => {
            const { cellStyle } = (0, helpers_1.headerStyles)(header, index, preset, extended, isEmailResults, isKeywordHarvesting, enableKeywordApproval);
            return ((0, jsx_runtime_1.jsx)("div", { className: `border-secondary-60 bg-secondary-40 table-cell truncate border-b p-[0.5rem] flex ${cellStyle}`, children: header.label }, index));
        });
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "sticky top-0 table-header-group", children: (0, jsx_runtime_1.jsx)("div", { className: "table-row text-left flex text-xs font-bold", children: renderHeaders() }) }));
}
exports.default = TableHeaders;
