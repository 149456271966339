import axios from 'axios';
import * as Sentry from '@sentry/react';

export const client = axios.create({
  baseURL: '/v1',
  headers: {
    Authorization: localStorage.getItem('officeToken'),
  },
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if ([401, 403].includes(error.response.status)) {
      window.location.href = '/login';
    }
    Sentry.captureException(error);
    return error;
  },
);
