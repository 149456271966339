import { useLoaderData, useParams } from 'react-router-dom';
import { Rule, SpApiAccount, User } from '@fern/types';
import { client } from '../api';
import Layout from '../components/Layout';

interface UserDetailsProps {
  params: {
    userId: string;
  };
}

type ProfileMetrics = {
  profileId: string;
  cost: string;
  adSales: string;
  acos: string;
};

interface LoaderData {
  rules: Rule[];
  user: User & { spApiAccounts: SpApiAccount[] };
  metrics: ProfileMetrics[];
  profileIds: number[];
  fromDate: string;
  toDate: string;
}

function getFromDate() {
  const date = new Date();
  date.setDate(0);
  date.setDate(1);
  return date.toISOString().split('T')[0];
}

function getToDate() {
  const date = new Date();
  date.setDate(0);
  return date.toISOString().split('T')[0];
}

export async function loader({
  params,
}: UserDetailsProps): Promise<LoaderData> {
  const rules = (await client.get(`/users/${params.userId}/rules`))
    .data as Rule[];
  const sortedRules = rules.sort((a, b) => {
    return (
      new Date(b.updatedAt ?? 0).getTime() -
      new Date(a.updatedAt ?? 0).getTime()
    );
  });
  const user = (await client.get(`/users/${params.userId}`)).data as User & {
    spApiAccounts: SpApiAccount[];
  };
  const profileIds = user.selectedProfiles.map((profile) => profile.id);
  const metrics =
    profileIds.length > 0
      ? ((
          await client.get(
            `reporting/profiles?fromDate=${getFromDate()}&toDate=${getToDate()}&profileIds=${profileIds.join(',')}`,
          )
        ).data.profileTotals as ProfileMetrics[])
      : [];

  const fromDate = getFromDate();
  const toDate = getToDate();

  if (!fromDate || !toDate) {
    throw Error('Missing dates');
  }

  return {
    rules: sortedRules,
    user,
    metrics,
    profileIds,
    fromDate,
    toDate,
  };
}

export default function UserDetails() {
  const { rules, user, metrics, fromDate, toDate } =
    useLoaderData() as LoaderData;
  const { userId } = useParams();

  return (
    <Layout
      nodes={[
        {
          path: `/users/${user.id}`,
          label: `${user.firstName} ${user.lastName}`,
        },
      ]}
    >
      <>
        <div className="mb-2 mt-8 flex-auto">
          <h2>User Details</h2>
        </div>
        <div className="mt-6">
          <div>
            <span className="font-bold">Email:</span> {user.email}
          </div>
          <div>
            <span className="font-bold">Rule count:</span> {rules.length}
          </div>
          <div>
            <span className="font-bold">
              Profiles ({user.selectedProfiles.length}):
            </span>
            <span>
              <ul className="list-disc ml-10">
                {user.selectedProfiles.map((profile) => (
                  <li>
                    <span key={profile.id} className="text-sm">
                      {profile.accountName} {profile.countryCode} -{' '}
                      {profile.id.toString()} -
                      {profile.selectedAt && (
                        <span title={profile.selectedAt}>
                          {new Intl.DateTimeFormat('en-GB', {
                            dateStyle: 'medium',
                          }).format(new Date(profile.selectedAt))}
                        </span>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </span>
          </div>
          <div>
            <span className="font-bold">Connected Amazon accounts:</span>{' '}
            {user.amazonAccounts.length}
          </div>
          <div>
            <span className="font-bold">SP-API accounts:</span>{' '}
            <span className="text-sm">
              <ul className="list-disc ml-10">
                {user.spApiAccounts.map((account) => (
                  <li>
                    {account.sellingPartnerId} - {account.platform} (
                    {account.id})
                  </li>
                ))}
              </ul>
            </span>
          </div>
        </div>
        <h3 className="mt-10">
          Profile Metrics ({fromDate} - {toDate})
        </h3>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-neutral-40">
                  <thead className="bg-secondary-40">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Profile ID
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Cost
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        Sales
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        ACoS
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-40 bg-white">
                    {user.selectedProfiles.map((profile) => {
                      const profileMetrics = metrics.find(
                        (metric) => parseInt(metric.profileId) === profile.id,
                      );
                      return (
                        <tr>
                          <td className="whitespace-nowrap px-4 text-sm font-medium ">
                            {profile.accountName} {profile.countryCode}
                          </td>
                          <td className="whitespace-nowrap px-4 text-sm font-medium ">
                            {profileMetrics?.cost ? profileMetrics?.cost : '-'}
                          </td>
                          <td className="whitespace-nowrap px-4 text-sm font-medium ">
                            {profileMetrics?.adSales
                              ? profileMetrics?.adSales
                              : '-'}
                          </td>
                          <td className="whitespace-nowrap px-4 text-sm font-medium ">
                            {profileMetrics?.acos
                              ? `${parseFloat(profileMetrics?.acos).toFixed(2)}%`
                              : '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {rules.length > 0 && (
          <>
            <h3 className="mt-10">User's Rules</h3>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-neutral-40">
                      <thead className="bg-secondary-40">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Rule name
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Last updated
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-neutral-40 bg-white">
                        {rules.map((rule, index) => (
                          <tr
                            key={index}
                            className={index % 2 ? 'bg-secondary-20' : ''}
                          >
                            <td className="whitespace-nowrap px-4 py-2 text-sm font-medium text-gray-900">
                              <a href={`/users/${userId}/rules/${rule.id}`}>
                                {rule.name}
                              </a>
                            </td>
                            <td className="whitespace-nowrap px-4 text-sm font-medium ">
                              <div className="flex gap-x-4">{rule.status}</div>
                            </td>
                            <td className="whitespace-nowrap px-4 text-sm font-medium">
                              <div className="flex gap-x-4">
                                {rule.updatedAt
                                  ? new Intl.DateTimeFormat('en-GB', {
                                      dateStyle: 'full',
                                    }).format(new Date(rule.updatedAt))
                                  : 'N/A'}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </Layout>
  );
}
