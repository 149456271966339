"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function Toggle({ text, enable, toggle, ariaLabel, theme, }) {
    const [enabled, setEnabled] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        setEnabled(enable);
    }, [enable]);
    function handleToggle() {
        setEnabled(!enabled);
        toggle && toggle(!enabled);
    }
    return ((0, jsx_runtime_1.jsxs)("button", { type: "button", className: `relative inline-flex h-6 w-10 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent
        transition-colors duration-200 ease-in-out focus:outline-none
        ${theme === 'dark'
            ? enabled
                ? 'bg-primary-100'
                : 'bg-neutral-60'
            : enabled
                ? 'bg-primary-80'
                : 'bg-neutral-40'}`, role: "switch", "aria-checked": enabled, "aria-label": ariaLabel, onClick: handleToggle, children: [(0, jsx_runtime_1.jsx)("span", { className: "sr-only", children: text }), (0, jsx_runtime_1.jsx)("span", { "aria-hidden": "true", className: `pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow transition duration-100 ease-in-out 
          ${enabled ? 'translate-x-4' : 'translate-x-0'}` })] }));
}
exports.default = Toggle;
