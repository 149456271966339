"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getField = exports.getPrefixLabel = exports.getFieldPrefix = exports.fieldWithoutPrefix = void 0;
const rules_1 = require("../../constants/rules");
const conditionFields_1 = require("../../constants/conditionFields");
const fieldWithoutPrefix = (fieldValue) => {
    // if no prefix just return the fieldValue
    if (!fieldValue.includes(':')) {
        return fieldValue;
    }
    return fieldValue.slice(fieldValue.indexOf(':') + 1);
};
exports.fieldWithoutPrefix = fieldWithoutPrefix;
const getFieldPrefix = (fieldValue) => {
    // if no prefix just return the fieldValue
    if (!fieldValue.includes(':')) {
        return '';
    }
    return fieldValue.slice(0, fieldValue.indexOf(':'));
};
exports.getFieldPrefix = getFieldPrefix;
const getPrefixLabel = (fieldValue) => {
    var _a;
    const prefix = (0, exports.getFieldPrefix)(fieldValue);
    return (_a = rules_1.FIELD_HEADER_LABELS_SINGULAR.find((label) => label.value === prefix)) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getPrefixLabel = getPrefixLabel;
// need to remove prefix to find the field
// Get field configuration from field value
const getField = (field) => {
    const fieldValue = (0, exports.fieldWithoutPrefix)(field);
    return Object.values(conditionFields_1.CONDITION_FIELDS).find((field) => field.value === fieldValue);
};
exports.getField = getField;
