"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActionsUsed = exports.getFirstActionDefault = exports.getFilteredActions = exports.getActionTab = exports.getNotice = void 0;
const actions_1 = require("../constants/actions");
const rules_1 = require("../constants/rules");
const getNotice = (pauseOrEnable) => {
    if (pauseOrEnable) {
        return `Will be applied when your conditions are met`;
    }
    return `Receive notifications when this rule is triggered`;
};
exports.getNotice = getNotice;
const getActionTab = (pauseOrEnable, status, stepActionType) => {
    let foundAction = {};
    actions_1.ACTION_GROUPS.forEach((group) => {
        const found = group.actions.find((action) => {
            if (pauseOrEnable) {
                return action.type === status;
            }
            return action.type === stepActionType;
        });
        if (found) {
            foundAction = found;
        }
    });
    return foundAction;
};
exports.getActionTab = getActionTab;
// don't return actions that are not available for the data type or the user
const getFilteredActions = (dataType, isInternalUser) => {
    return actions_1.ACTION_GROUPS.flatMap((group) => group.actions).filter((action) => {
        // for now, only show pause/enable for campaigns (for external users)
        if (!isInternalUser &&
            dataType === rules_1.CAMPAIGNS &&
            (action.type === 'paused' || action.type === 'enabled')) {
            return true;
        }
        return (action.availableFor.includes(dataType) &&
            (action.enabled === 'all' ||
                (action.enabled === 'internal' && isInternalUser)));
    });
};
exports.getFilteredActions = getFilteredActions;
const getFirstActionDefault = (dataType, actions, isInternalUser) => {
    var _a, _b, _c;
    const filteredActions = (0, exports.getFilteredActions)(dataType, isInternalUser);
    if (actions.length > 0) {
        const unusedActions = filteredActions.filter((action) => {
            const actionsInUse = actions.filter((ruleAction) => {
                if ((action.type === 'paused' || action.type === 'enabled') &&
                    ruleAction.type === actions_1.CHANGE_STATUS) {
                    return true;
                }
                if (action.type === ruleAction.type) {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (actionsInUse.length > 0) {
                return false;
            }
            return true;
        });
        if (unusedActions.length === 0) {
            return actions_1.ACTION_DEFAULTS['updateBid'];
        }
        if (((_a = unusedActions[0]) === null || _a === void 0 ? void 0 : _a.type) === 'paused' ||
            ((_b = unusedActions[0]) === null || _b === void 0 ? void 0 : _b.type) === 'enabled') {
            return actions_1.ACTION_DEFAULTS[actions_1.CHANGE_STATUS];
        }
        return actions_1.ACTION_DEFAULTS[(_c = unusedActions[0]) === null || _c === void 0 ? void 0 : _c.type];
    }
    const firstAction = filteredActions[0];
    if (!firstAction) {
        throw Error('Missing action');
    }
    if (firstAction &&
        (firstAction.type === 'paused' || firstAction.type === 'enabled')) {
        return actions_1.ACTION_DEFAULTS[actions_1.CHANGE_STATUS];
    }
    return actions_1.ACTION_DEFAULTS[firstAction.type];
};
exports.getFirstActionDefault = getFirstActionDefault;
const getActionsUsed = (actions, isInternalUser, DataType) => {
    const actionTypesUsed = actions.map((action) => action.type);
    const checkChangeStatus = actionTypesUsed.includes(actions_1.CHANGE_STATUS);
    const checkCampaignBudgets = actionTypesUsed.includes(actions_1.UPDATE_BUDGET);
    const checkTargetBids = actionTypesUsed.includes(actions_1.UPDATE_BID);
    const checkSendEmail = actionTypesUsed.includes(actions_1.SEND_EMAIL);
    const checkHarvestKeywords = actionTypesUsed.includes(actions_1.HARVEST_KEYWORDS);
    const usedInternalActions = checkSendEmail &&
        checkChangeStatus &&
        (checkCampaignBudgets || checkTargetBids);
    const usedExternalActions = checkSendEmail && (checkChangeStatus || checkTargetBids);
    const usedSearchTermsActions = DataType === rules_1.SEARCH_TERMS && checkHarvestKeywords && checkSendEmail;
    if (!isInternalUser && usedExternalActions) {
        return true;
    }
    if (isInternalUser && usedInternalActions) {
        return true;
    }
    if (isInternalUser && usedSearchTermsActions) {
        return true;
    }
    return false;
};
exports.getActionsUsed = getActionsUsed;
