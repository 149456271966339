"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapResultToKeywordApproval = exports.headerStyles = void 0;
function headerStyles(header, index, preset, extended, isEmailResults, isKeywordHarvesting, enableKeywordApproval) {
    const isFirst = index === 0;
    const isLast = index + 1 === preset.length;
    const lastColStyles = 'rounded-r-sm border-b !border-secondary-100 !bg-secondary-80 text-white';
    const isSearchTermCol = header.value === 'searchTerm';
    const isMarketplaceCol = header.value === 'countryCode';
    const cellStyle = `
  ${isFirst && 'rounded-l-sm'} 
  ${isFirst && isKeywordHarvesting && enableKeywordApproval && 'pl-[3.25rem]'}
  ${isLast && (!isEmailResults && !isKeywordHarvesting ? lastColStyles : 'rounded-r-sm ')} 
  ${isMarketplaceCol && enableKeywordApproval && !extended && 'max-w-[6rem] flex-auto pl-[3.25rem]'}
  ${isSearchTermCol ? lastColStyles : ''}`;
    return {
        cellStyle,
    };
}
exports.headerStyles = headerStyles;
function mapResultToKeywordApproval(result) {
    return {
        profileId: result.profileId,
        searchTerm: result.searchTerm.toString(),
        status: 'APPROVED',
        campaignId: result.destinationCampaignId,
        adGroupIds: result.destinationAdGroupIds,
        adType: result.adType,
        matchTypes: result.matchTypes,
        targetingType: result.targetingType,
        bid: result.bid,
        negateKeywords: {
            negate: result.negateKeywords,
            matchTypes: result.negativeMatchTypes,
        },
        sourceCampaignId: result.sourceCampaignId,
        sourceAdGroupIds: result.sourceAdGroupIds,
    };
}
exports.mapResultToKeywordApproval = mapResultToKeywordApproval;
