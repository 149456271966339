import { useLoaderData } from 'react-router-dom';
import { Rule, User } from '@fern/types';
import { client } from '../api';
import { StepDetails } from '../components/StepDetails';
import { isRuleValid } from '@fern/ui/helpers/rules/isValid';
import Layout from '../components/Layout';

interface UserDetailsProps {
  params: {
    userId: string;
    ruleId: string;
  };
}

interface OfficeUser extends User {
  profilesWithSpendTarget: Array<string>;
}

interface LoaderData {
  rule: Rule;
  user: OfficeUser;
}

export async function loader({
  params,
}: UserDetailsProps): Promise<LoaderData> {
  const rule = (
    await client.get(`/users/${params.userId}/rules/${params.ruleId}`)
  ).data as Rule;
  const user = (await client.get(`/users/${params.userId}`)).data as OfficeUser;
  return { rule, user };
}

export default function RuleDetails() {
  const { rule, user } = useLoaderData() as LoaderData;
  const { profilesWithSpendTarget } = user || [];

  const allProfilesHaveSpendTargets =
    profilesWithSpendTarget &&
    rule.profileIds.every((id) =>
      profilesWithSpendTarget.includes(id.toString()),
    );

  const isValid = isRuleValid(rule, allProfilesHaveSpendTargets || false);
  return (
    <Layout
      nodes={[
        {
          path: `/users/${user.id}`,
          label: `${user.firstName} ${user.lastName}`,
        },
        {
          path: `/users/${user.id}/rules/${rule.id}`,
          label: rule.name,
        },
      ]}
    >
      <>
        <div className="py-10">
          <div className="mb-10 text-3xl font-bold">{rule.name}</div>
          {rule.steps.map((step, index) => (
            <StepDetails
              step={step}
              rule={rule}
              ruleId={rule.id}
              userId={user.id}
              stepId={index + 1}
              key={index}
              index={index}
              user={user}
              isLive={rule.status === 'ENABLED'}
              isRuleValid={isValid}
            />
          ))}
        </div>
      </>
    </Layout>
  );
}
