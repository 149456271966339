"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRuleValid = exports.ruleStepsValid = exports.periodIsValid = exports.scopeIsValid = void 0;
const rules_1 = require("../../constants/rules");
const scopeIsValid = (rule) => {
    const hasDataType = !!rule.dataType;
    // Product signals rule do not have ad types
    const hasAdTypes = rule.dataType === rules_1.PRODUCT_SIGNALS ||
        (rule.adTypes && rule.adTypes.length > 0);
    // Product signals require asins selected
    const hasAsins = (rule.dataType === rules_1.PRODUCT_SIGNALS &&
        rule.asins &&
        rule.asins.length > 0) ||
        rule.dataType !== rules_1.PRODUCT_SIGNALS;
    // Non product signals rules require profileIds
    const hasProfileIds = rule.dataType === rules_1.PRODUCT_SIGNALS ||
        (rule.profileIds && rule.profileIds.length > 0);
    return rule.adTypes && hasAdTypes && hasDataType && hasAsins && hasProfileIds;
};
exports.scopeIsValid = scopeIsValid;
function periodIsValid(dataType, period) {
    if (!dataType) {
        return false;
    }
    if (dataType === rules_1.PRODUCT_SIGNALS) {
        return true;
    }
    if (!period) {
        return false;
    }
    if (dataType === rules_1.SEARCH_TERMS) {
        return rules_1.VALID_PERIODS_FOR_SEARCH_TERMS.includes(period);
    }
    if (dataType === rules_1.TARGETS) {
        return rules_1.VALID_PERIODS_FOR_TARGETS.includes(period);
    }
    if (dataType === rules_1.CAMPAIGNS) {
        return rules_1.VALID_PERIODS_FOR_CAMPAIGNS.includes(period);
    }
    return false;
}
exports.periodIsValid = periodIsValid;
// Returns an array of true/false so we can apply to each step
const ruleStepsValid = (rule, allProfilesHaveSpendTargets) => {
    return (rule.steps || []).map((step) => {
        // if step has marketplace fields and not all profiles have budgets then step is invalid
        const hasMarketplaceFields = step.conditionGroups.some((group) => {
            return group.conditions.some((condition) => {
                return condition.field.includes('marketplace');
            });
        });
        if (hasMarketplaceFields && !allProfilesHaveSpendTargets) {
            return false;
        }
        const { actions, conditionGroups, period } = step;
        const hasAction = actions && actions.length > 0;
        const allConditionsValid = conditionGroups.every((group) => {
            return group.conditions.every((condition) => {
                // check if condition is a metric condition or a value condition
                const metricCondition = condition;
                const valueCondition = condition;
                const isValueCondition = condition && condition.hasOwnProperty('value');
                const expressionValue = valueCondition.value;
                const regularValue = isValueCondition
                    ? valueCondition.value
                    : metricCondition.metric;
                if (isValueCondition && expressionValue.hasOwnProperty('metric')) {
                    return (condition.field &&
                        condition.operator &&
                        (expressionValue === null || expressionValue === void 0 ? void 0 : expressionValue.metric) &&
                        (expressionValue === null || expressionValue === void 0 ? void 0 : expressionValue.value));
                }
                return condition.field && condition.operator && regularValue.length;
            });
        });
        const hasCorrectPeriodForDataType = periodIsValid(rule.dataType, period);
        if (hasAction && allConditionsValid && hasCorrectPeriodForDataType) {
            return true;
        }
        return false;
    });
};
exports.ruleStepsValid = ruleStepsValid;
function isRuleValid(rule, allProfilesHaveSpendTargets) {
    return (((0, exports.scopeIsValid)(rule) &&
        (0, exports.ruleStepsValid)(rule, allProfilesHaveSpendTargets).every((isStepValid) => isStepValid === true)) ||
        false);
}
exports.isRuleValid = isRuleValid;
