import { useRouteError } from 'react-router-dom';

export function ErrorPage() {
  const error = useRouteError() as Error;
  console.error(error);

  return (
    <div id="error-page" className="m-10">
      <h1 className="text-4xl my-4">Error</h1>
      <div className="text-xl my-4">
        Sorry, an unexpected error has occurred.
      </div>
      <p>
        <i>{error.message}</i>
      </p>
    </div>
  );
}
