"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const steps_1 = require("../../helpers/steps");
const rules_1 = require("../../constants/rules");
const fields_1 = require("../../helpers/rules/fields");
function useCondition(condition, groupOperator) {
    var _a, _b;
    const metricCondition = condition;
    const valueCondition = condition;
    const isValueCondition = condition && condition.hasOwnProperty('value');
    const { field, operator } = condition;
    const value = isValueCondition
        ? valueCondition.value
        : metricCondition.metric;
    const conditionValue = value;
    const metric = conditionValue === null || conditionValue === void 0 ? void 0 : conditionValue.metric;
    const operatorLabel = (0, steps_1.getOperatorLabel)(field, operator);
    const fieldLabel = (0, steps_1.getFieldLabel)(field);
    const fieldConfig = (0, fields_1.getField)(field);
    const groupOperatorLabel = (0, steps_1.getGroupOperator)(groupOperator);
    const prefixLabel = (0, fields_1.getPrefixLabel)(field);
    // for condition values that have prefixes ie products:dynamic_acos
    const conditionValuePrefixLabel = isValueCondition && typeof value == 'string' && (0, fields_1.getPrefixLabel)(value);
    const conditionValueFieldLabel = isValueCondition && typeof value == 'string' && (0, steps_1.getFieldLabel)(value);
    const expressionLabel = (_a = Object.values(rules_1.EXPRESSION_OPTIONS)
        .flatMap((option) => option)
        .find((option) => option.value === metric)) === null || _a === void 0 ? void 0 : _a.label;
    // if marketplace field and condition dropdown selected get label
    const marketplaceConditionLabel = (_b = rules_1.MARKETPLACE_FIELDS.find((field) => field.value === value)) === null || _b === void 0 ? void 0 : _b.label;
    return {
        operatorLabel,
        fieldLabel,
        groupOperatorLabel,
        prefixLabel,
        conditionValuePrefixLabel,
        conditionValueFieldLabel,
        expressionLabel,
        marketplaceConditionLabel,
        fieldConfig,
        metric,
        field,
        value,
    };
}
exports.default = useCondition;
