"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const actions_1 = require("../../../constants/actions");
const icons_1 = require("@fern/icons/");
function Notices({ actionTimestamp, isLive, currentAction, incompleteData, }) {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!actionTimestamp && !isLive && ((0, jsx_runtime_1.jsxs)("div", { className: "bg-primary-40 border-primary-80 mb-3 mt-2 flex h-[2.25rem] w-fit rounded border px-[0.688rem] py-[0.4rem]", children: [(0, jsx_runtime_1.jsx)("span", { className: "mr-1.5 ", children: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}) }), (0, jsx_runtime_1.jsx)("span", { className: "mt-[0.032rem] text-sm !leading-5", children: "This is how the rule would be applied if it were live" })] })), currentAction &&
                currentAction.type === actions_1.HARVEST_KEYWORDS &&
                !actionTimestamp && ((0, jsx_runtime_1.jsxs)("div", { className: "bg-primary-40 border-primary-80 mb-3 mt-2 flex h-[2.25rem] w-fit rounded border px-[0.688rem] py-[0.4rem]", children: [(0, jsx_runtime_1.jsx)("span", { className: "mr-1.5 ", children: (0, jsx_runtime_1.jsx)(icons_1.InfoIcon, {}) }), (0, jsx_runtime_1.jsx)("span", { className: "mt-[0.032rem] text-sm !leading-5", children: "This is a preview of the results" })] })), currentAction &&
                currentAction.type === actions_1.SEND_EMAIL &&
                actionTimestamp && ((0, jsx_runtime_1.jsxs)("div", { className: "bg-primary-40 border-primary-80 my-4 flex h-[2.25rem] w-fit rounded border px-[0.688rem] py-[0.4rem]", children: [(0, jsx_runtime_1.jsx)("span", { className: "mr-1.5 mt-0.5", children: (0, jsx_runtime_1.jsx)(icons_1.TickCircle, { iconColour: "stroke-white", background: "fill-primary-100" }) }), (0, jsx_runtime_1.jsxs)("span", { className: "mt-[0.032rem] text-sm !leading-5", children: ["Email alert was sent on ", actionTimestamp] })] })), incompleteData && ((0, jsx_runtime_1.jsx)("div", { className: "relative mx-auto my-2.5 mb-4 max-h-fit rounded bg-[#FFF2F2]", children: (0, jsx_runtime_1.jsxs)("div", { className: "relative mx-auto flex px-3 py-2 ", children: [(0, jsx_runtime_1.jsx)("span", { className: "m-auto", children: (0, jsx_runtime_1.jsx)(icons_1.AlertIcon, {}) }), (0, jsx_runtime_1.jsx)("span", { className: "mt-0.5 w-max pl-1.5 text-sm font-thin leading-[1.05rem] text-[#001233]", children: "We're still downloading your data. Some results may be missing in the table below. Please try reloading the page in a few minutes." })] }) }))] }));
}
exports.default = Notices;
