import { User } from '@fern/types';
import { client } from '../api';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { ReactElement } from 'react';

interface UserDetailsProps {
  params: {
    userId: string;
  };
}

interface LoaderData {
  user: User;
}

interface Props {
  children: ReactElement;
  nodes: { path: string; label: string }[];
}

export async function loader({
  params,
}: UserDetailsProps): Promise<LoaderData> {
  const user = (await client.get(`/users/${params.userId}`)).data as User;

  return { user };
}

export default function Layout({ children, nodes }: Props) {
  return (
    <>
      <Breadcrumbs nodes={nodes} />
      {children}
    </>
  );
}
