"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
function PrimaryButton({ children, disabled, size = 'md', onClick, colour = 'bg-primary-100', width, }) {
    const largeStyle = 'px-7 h-[3.25rem] text-base';
    const mediumStyle = 'px-5 h-[2.5625rem] text-sm';
    const smallStyle = 'px-5 h-[2rem] text-sm';
    function getStyle() {
        if (size === 'lg') {
            return largeStyle;
        }
        if (size === 'md') {
            return mediumStyle;
        }
        return smallStyle;
    }
    return ((0, jsx_runtime_1.jsx)("button", { onClick: onClick, disabled: disabled, className: `${getStyle()} disabled:bg-neutral-40 disabled:border-neutral-40 font-bold disabled:cursor-not-allowed disabled:hover:brightness-100 
     ${colour} ${width} rounded-full text-white hover:brightness-90 `, children: children }));
}
exports.default = PrimaryButton;
