"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showPercentIcon = void 0;
function showPercentIcon(selectedField) {
    if (selectedField === 'click_through_rate' ||
        selectedField === 'ad_conversion_rate' ||
        selectedField === 'acos' ||
        selectedField === 'marketplace_acos' ||
        selectedField === 'tacos') {
        return true;
    }
    return false;
}
exports.showPercentIcon = showPercentIcon;
