"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function useClickOutside(setOpen, ref, clickableRef) {
    // ref - element you want to close if you click outside of
    // clickableRef - element you want to still be able to click
    // useEffect(() => {
    function handleClickOutside(event) {
        if (ref.current &&
            // @ts-ignore
            !ref.current.contains(event.target) &&
            clickableRef.current &&
            // @ts-ignore
            !clickableRef.current.contains(event.target)) {
            setOpen(false);
        }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
    };
    // }, [ref, clickableRef, setOpen]);
}
exports.default = useClickOutside;
