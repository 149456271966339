"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SEARCH_TERMS_FIELDS = exports.TARGETS_FIELDS = exports.CAMPAIGN_FIELDS = exports.ALL_DATA_TYPE_FIELDS = exports.AD_OPTIONS = exports.FIELD_HEADER_LABELS_SINGULAR = exports.FIELD_HEADER_LABELS = exports.DATA_TYPE_OPTIONS = exports.DATA_TYPE_OPTIONS_INTERNAL = exports.DATA_TYPE_LABELS = exports.DATE_OPTIONS = exports.ARCHIVED = exports.DRAFT = exports.DISABLED = exports.ENABLED = exports.APPROVED = exports.REJECTED = exports.MATCH_TYPES = exports.TARGETING_TYPES = exports.KEYWORD = exports.PRODUCT = exports.DEFAULT_CAMPAIGN_LINK = exports.GROUP_OPERATORS = exports.OPERATORS = exports.EXPRESSION_OPTIONS = exports.BUDGET_OPTION = exports.INPUT_TYPE_OPTIONS = exports.DROPDOWN_INPUT = exports.REGULAR_INPUT = exports.DEFAULT_USER_INVITE = exports.DEFAULT_RULE = exports.DEFAULT_STEPS = exports.DEFAULT_STEP = exports.VALID_PERIODS_FOR_SEARCH_TERMS = exports.VALID_PERIODS_FOR_CAMPAIGNS = exports.VALID_PERIODS_FOR_TARGETS = exports.SHORT_PERIOD = exports.LONG_PERIOD = exports.DEFAULT_PERIOD = exports.DEFAULT_GROUP = exports.DEFAULT_CONDITION = exports.FIELD_HEADERS = exports.MARKETPLACE = exports.PRODUCT_SIGNALS = exports.ADS = exports.PRODUCTS = exports.SEARCH_TERMS = exports.CAMPAIGNS = exports.AD_GROUPS = exports.TARGETS = void 0;
exports.GROUPED_CONDITION_FIELDS_EXTERNAL = exports.GROUPED_CONDITION_FIELDS = exports.PRODUCT_SIGNALS_FIELDS = exports.PRODUCTS_FIELDS = exports.MARKETPLACE_FIELDS = void 0;
const conditionFields_1 = require("./conditionFields");
const deepClone_1 = require("../helpers/deepClone");
exports.TARGETS = 'targets';
exports.AD_GROUPS = 'adGroups';
exports.CAMPAIGNS = 'campaigns';
exports.SEARCH_TERMS = 'searchTerms';
exports.PRODUCTS = 'products';
exports.ADS = 'ads';
exports.PRODUCT_SIGNALS = 'productSignals';
exports.MARKETPLACE = 'marketplace';
exports.FIELD_HEADERS = {
    marketplace: 'marketplace',
};
exports.DEFAULT_CONDITION = {
    field: '',
    operator: '',
    value: '',
};
exports.DEFAULT_GROUP = {
    operator: '&&',
    conditions: [Object.assign({}, exports.DEFAULT_CONDITION)],
};
exports.DEFAULT_PERIOD = '14';
exports.LONG_PERIOD = '30';
exports.SHORT_PERIOD = '5';
exports.VALID_PERIODS_FOR_TARGETS = [exports.DEFAULT_PERIOD, exports.LONG_PERIOD];
exports.VALID_PERIODS_FOR_CAMPAIGNS = [
    exports.SHORT_PERIOD,
    exports.DEFAULT_PERIOD,
    exports.LONG_PERIOD,
];
exports.VALID_PERIODS_FOR_SEARCH_TERMS = [exports.LONG_PERIOD];
exports.DEFAULT_STEP = {
    operator: '&&',
    period: exports.DEFAULT_PERIOD,
    conditionGroups: [Object.assign({}, exports.DEFAULT_GROUP)],
    actions: [],
    name: null,
};
exports.DEFAULT_STEPS = [Object.assign({}, exports.DEFAULT_STEP)];
exports.DEFAULT_RULE = {
    adTypes: [],
    dataType: exports.TARGETS,
    profileIds: [],
    name: 'Untitled rule',
    status: 'DRAFT',
    steps: (0, deepClone_1.deepClone)(exports.DEFAULT_STEPS),
};
exports.DEFAULT_USER_INVITE = {
    name: '',
    email: '',
    status: 'pending',
    id: '',
    invitedBy: '',
    createdAt: '',
};
exports.REGULAR_INPUT = 'regularInput';
exports.DROPDOWN_INPUT = 'dropdownInput';
exports.INPUT_TYPE_OPTIONS = [
    { label: 'Enter value', value: exports.REGULAR_INPUT },
    { label: 'Select field', value: exports.DROPDOWN_INPUT },
];
exports.BUDGET_OPTION = { label: 'Budget', value: 'budget' };
exports.EXPRESSION_OPTIONS = {
    clicks: [{ label: 'Mktp CR Clicks', value: 'profileCrClicks' }],
    bid: [{ label: 'CPC', value: 'costPerClick' }],
    cost: [exports.BUDGET_OPTION],
    averageDailyCost: [exports.BUDGET_OPTION],
    product: [conditionFields_1.CONDITION_FIELDS.averageCr],
};
exports.OPERATORS = [
    { label: 'Contains', value: 'contains', type: 'string' },
    {
        label: 'Does not contain',
        value: 'not_contains',
        type: 'string',
    },
    { label: 'Exactly matches', value: '=', type: 'string' },
    {
        label: 'Does not exactly match',
        value: '!=',
        type: 'string',
    },
    {
        label: 'Begins with',
        value: 'begins_with',
        type: 'string',
    },
    {
        label: 'Ends with',
        value: 'ends_with',
        type: 'string',
    },
    { label: 'Greater than', value: '>', type: 'number' },
    {
        label: 'Greater than or equal to',
        value: '>=',
        type: 'number',
    },
    { label: 'Less than', value: '<', type: 'number' },
    {
        label: 'Less than or equal to',
        value: '<=',
        type: 'number',
    },
    { label: 'Equal to', value: '=', type: 'number' },
    { label: 'Not equal to', value: '!=', type: 'number' },
    {
        label: 'Changes to',
        value: 'changes_to',
        type: 'boolean',
    },
];
exports.GROUP_OPERATORS = [
    { label: 'or', value: '||' },
    { label: 'and', value: '&&' },
];
exports.DEFAULT_CAMPAIGN_LINK = {
    profileId: '',
    id: '',
    source: {
        campaignName: '',
        campaignId: '',
        adGroups: [{ adGroupName: '', adGroupId: '' }],
    },
    destination: {
        campaignName: '',
        campaignId: '',
        adGroups: [{ adGroupName: '', adGroupId: '' }],
    },
    matchTypes: [],
    targetingType: 'keyword',
    bid: { fixedBid: '' },
    negateKeywords: { negate: false },
    createdAt: '',
};
exports.PRODUCT = 'product';
exports.KEYWORD = 'keyword';
exports.TARGETING_TYPES = [
    { label: 'Keyword', value: exports.KEYWORD },
    { label: 'Product', value: exports.PRODUCT },
];
exports.MATCH_TYPES = [
    { label: 'Broad', value: 'broad' },
    { label: 'Phrase', value: 'phrase' },
    { label: 'Exact', value: 'exact' },
];
exports.REJECTED = 'REJECTED';
exports.APPROVED = 'APPROVED';
exports.ENABLED = 'ENABLED';
exports.DISABLED = 'DISABLED';
exports.DRAFT = 'DRAFT';
exports.ARCHIVED = 'ARCHIVED';
exports.DATE_OPTIONS = [
    { value: exports.SHORT_PERIOD, label: 'Last 5 days' },
    { value: exports.DEFAULT_PERIOD, label: 'Up to 14 days ago' },
    { value: exports.LONG_PERIOD, label: 'Up to 30 days ago' },
];
// labels
exports.DATA_TYPE_LABELS = [
    { value: exports.TARGETS, label: 'Targets' },
    { value: exports.CAMPAIGNS, label: 'Campaigns' },
    { value: exports.AD_GROUPS, label: 'Ad groups' },
    { value: exports.SEARCH_TERMS, label: 'Search terms' },
    { value: exports.PRODUCT_SIGNALS, label: 'Product Signals' },
    { value: exports.PRODUCTS, label: 'Products' },
];
exports.DATA_TYPE_OPTIONS_INTERNAL = [
    { value: exports.TARGETS, label: 'Targets' },
    { value: exports.CAMPAIGNS, label: 'Campaigns' },
    { value: exports.SEARCH_TERMS, label: 'Search terms' },
    { value: exports.PRODUCT_SIGNALS, label: 'Product Signals' },
];
exports.DATA_TYPE_OPTIONS = [
    { value: exports.TARGETS, label: 'Targets' },
    { value: exports.CAMPAIGNS, label: 'Campaigns' },
    { value: exports.SEARCH_TERMS, label: 'Search terms' },
];
exports.FIELD_HEADER_LABELS = [
    { value: exports.TARGETS, label: 'Targets' },
    { value: exports.CAMPAIGNS, label: 'Campaigns' },
    { value: exports.AD_GROUPS, label: 'Ad groups' },
    { value: exports.FIELD_HEADERS.marketplace, label: 'Marketplace' },
    { value: exports.PRODUCTS, label: 'Products' },
    { value: exports.SEARCH_TERMS, label: 'Search terms' },
    { value: exports.PRODUCT_SIGNALS, label: 'Product Signals' },
];
exports.FIELD_HEADER_LABELS_SINGULAR = [
    { value: exports.TARGETS, label: 'Target' },
    { value: exports.CAMPAIGNS, label: 'Campaign' },
    { value: exports.AD_GROUPS, label: 'Ad group' },
    { value: exports.PRODUCTS, label: 'Product' },
    { value: exports.FIELD_HEADERS.marketplace, label: 'Mktp' },
    { value: exports.SEARCH_TERMS, label: 'Search term' },
    { value: exports.PRODUCT_SIGNALS, label: 'Product Signals' },
];
exports.AD_OPTIONS = [
    { value: 'SB', label: 'Sponsored Brands' },
    { value: 'SD', label: 'Sponsored Display' },
    { value: 'SP', label: 'Sponsored Product' },
];
// Data Type fields
exports.ALL_DATA_TYPE_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.clicks,
    conditionFields_1.CONDITION_FIELDS.impressions,
    conditionFields_1.CONDITION_FIELDS.spend,
    conditionFields_1.CONDITION_FIELDS.cpc,
    conditionFields_1.CONDITION_FIELDS.ctr,
    conditionFields_1.CONDITION_FIELDS.adSales,
    conditionFields_1.CONDITION_FIELDS.adOrders,
    conditionFields_1.CONDITION_FIELDS.acos,
    conditionFields_1.CONDITION_FIELDS.roas,
    conditionFields_1.CONDITION_FIELDS.adConversionRate,
];
exports.CAMPAIGN_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.newCampaignName,
    conditionFields_1.CONDITION_FIELDS.status,
    conditionFields_1.CONDITION_FIELDS.budget,
    conditionFields_1.CONDITION_FIELDS.averageDailyCost,
];
exports.TARGETS_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.adGroupName,
    conditionFields_1.CONDITION_FIELDS.campaignName,
    conditionFields_1.CONDITION_FIELDS.targetingText,
    conditionFields_1.CONDITION_FIELDS.matchType,
    conditionFields_1.CONDITION_FIELDS.bid,
];
exports.SEARCH_TERMS_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.searchTerm,
    conditionFields_1.CONDITION_FIELDS.bid,
    conditionFields_1.CONDITION_FIELDS.campaignName,
    conditionFields_1.CONDITION_FIELDS.targetingText,
];
exports.MARKETPLACE_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.monthlySpendTarget,
    conditionFields_1.CONDITION_FIELDS.monthToDateSpendGoal,
    conditionFields_1.CONDITION_FIELDS.monthToDateSpendActual,
];
exports.PRODUCTS_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.asin,
    conditionFields_1.CONDITION_FIELDS.revenue,
    conditionFields_1.CONDITION_FIELDS.tacos,
    conditionFields_1.CONDITION_FIELDS.buyBox,
    conditionFields_1.CONDITION_FIELDS.totalCr,
    conditionFields_1.CONDITION_FIELDS.organicCr,
    conditionFields_1.CONDITION_FIELDS.totalOrders,
    conditionFields_1.CONDITION_FIELDS.totalUnits,
    conditionFields_1.CONDITION_FIELDS.sessions,
    conditionFields_1.CONDITION_FIELDS.pageViews,
    conditionFields_1.CONDITION_FIELDS.fbaStockAvailable,
    conditionFields_1.CONDITION_FIELDS.deliveryDays,
];
exports.PRODUCT_SIGNALS_FIELDS = [
    conditionFields_1.CONDITION_FIELDS.winningBuyBox,
    conditionFields_1.CONDITION_FIELDS.fbaStockAvailable,
    conditionFields_1.CONDITION_FIELDS.normalDeliveryWindow,
    // CONDITION_FIELDS.status, (might need a productStatus field)
    // CONDITION_FIELDS.pricingHealth,
    // CONDITION_FIELDS.promotionStatus,
    conditionFields_1.CONDITION_FIELDS.primeEligibilityStatus,
    conditionFields_1.CONDITION_FIELDS.mainCategorySalesRank,
    conditionFields_1.CONDITION_FIELDS.subCategorySalesRank,
];
exports.GROUPED_CONDITION_FIELDS = {
    [exports.TARGETS]: {
        [exports.TARGETS]: [...exports.TARGETS_FIELDS, ...exports.ALL_DATA_TYPE_FIELDS],
        marketplace: [...exports.MARKETPLACE_FIELDS],
        [exports.PRODUCTS]: [...exports.PRODUCTS_FIELDS],
    },
    [exports.CAMPAIGNS]: {
        [exports.CAMPAIGNS]: [...exports.CAMPAIGN_FIELDS, ...exports.ALL_DATA_TYPE_FIELDS],
        marketplace: [...exports.MARKETPLACE_FIELDS],
        [exports.PRODUCTS]: [...exports.PRODUCTS_FIELDS],
    },
    [exports.SEARCH_TERMS]: {
        [exports.SEARCH_TERMS]: [...exports.SEARCH_TERMS_FIELDS, ...exports.ALL_DATA_TYPE_FIELDS],
    },
    [exports.PRODUCT_SIGNALS]: {
        [exports.PRODUCT_SIGNALS]: [...exports.PRODUCT_SIGNALS_FIELDS],
    },
};
exports.GROUPED_CONDITION_FIELDS_EXTERNAL = {
    [exports.TARGETS]: {
        [exports.TARGETS]: [...exports.TARGETS_FIELDS, ...exports.ALL_DATA_TYPE_FIELDS],
        marketplace: [...exports.MARKETPLACE_FIELDS],
    },
    [exports.CAMPAIGNS]: {
        [exports.CAMPAIGNS]: [...exports.CAMPAIGN_FIELDS, ...exports.ALL_DATA_TYPE_FIELDS],
        marketplace: [...exports.MARKETPLACE_FIELDS],
    },
    [exports.SEARCH_TERMS]: {
        [exports.SEARCH_TERMS]: [...exports.SEARCH_TERMS_FIELDS, ...exports.ALL_DATA_TYPE_FIELDS],
    },
};
