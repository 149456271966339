import { FullLogo } from '@fern/icons';
import { useParams, Link } from 'react-router-dom';

export default function Menu(): JSX.Element {
  const { userId } = useParams();

  return (
    <>
      <div className="fixed inset-y-0 z-50 flex w-64 flex-col">
        <div className="flex grow flex-col overflow-y-auto border-r border-neutral-40 bg-white px-6">
          <div className="flex items-center my-8">
            <a
              href="/"
              aria-current="page"
              className="link-block w-inline-block w--current"
            >
              <FullLogo />
            </a>
          </div>
          <nav className="flex flex-col">
            <ul className="flex flex-1 flex-col gap-y-3">
              <li>
                <ul className="-mx-7 space-y-1">
                  <li>
                    <a
                      href="/"
                      className="group flex bg-neutral-20 py-3 px-6 font-bold leading-6 text-neutral-80 hover:text-neutral-100"
                    >
                      Users
                    </a>
                  </li>
                </ul>
              </li>
              {userId != null && (
                <li>
                  <ul className="-mx-7 space-y-1">
                    <li>
                      <Link
                        to={`/users/${userId}`}
                        className="group flex bg-neutral-20 py-3 px-6 font-bold leading-6 text-neutral-80 hover:text-neutral-100"
                      >
                        Rules
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
