import Menu from './components/Menu';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div>
      <Menu />
      <main className="relative left-64 right-64 mr-64 px-12">
        <Outlet />
      </main>
    </div>
  );
}

export default App;
