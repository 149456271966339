"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrencySymbol = exports.formatCurrency = void 0;
const localisation_1 = require("../localisation/localisation");
const formatCurrency = (value, currencyCode, subUnit) => {
    return (0, localisation_1.getInternationalNumber)(value, currencyCode, 'currency', subUnit, subUnit);
};
exports.formatCurrency = formatCurrency;
const getCurrencySymbol = (currencyCode) => {
    const currency = new Intl.NumberFormat((0, localisation_1.browserLocale)(), {
        style: 'currency',
        currency: currencyCode,
    })
        .formatToParts(1)
        .find((x) => x.type === 'currency');
    return currency ? currency.value : '';
};
exports.getCurrencySymbol = getCurrencySymbol;
