"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterDuplicates = exports.resultsWaitingApproval = exports.approvalsMatch = exports.resultMatch = void 0;
const searchTermMatch = (searchTerm, comparisonSearchTerm) => {
    return searchTerm === comparisonSearchTerm;
};
const campaignIdMatch = (campaignId, comparisonCampaignId) => {
    return campaignId === comparisonCampaignId;
};
const targetingTypeMatch = (targetingType, comparisonTargetingType) => {
    return targetingType === comparisonTargetingType;
};
const adGroupsMatch = (adGroupIds, comparisonAdGroupIds) => {
    return ((!adGroupIds && !comparisonAdGroupIds) ||
        (adGroupIds &&
            comparisonAdGroupIds &&
            adGroupIds.every((adGroupId, index) => comparisonAdGroupIds && adGroupId === comparisonAdGroupIds[index])));
};
const matchTypesMatch = (matchTypes, comparisonMatchTypes) => {
    return ((!matchTypes && !comparisonMatchTypes) ||
        (matchTypes &&
            comparisonMatchTypes &&
            matchTypes.every((matchType, index) => matchType === comparisonMatchTypes[index])));
};
const resultMatch = (result, approval) => {
    if (!approval) {
        return false;
    }
    return (searchTermMatch(approval.searchTerm, result.searchTerm) &&
        campaignIdMatch(approval.campaignId, result.destinationCampaignId) &&
        targetingTypeMatch(approval.targetingType, result.targetingType) &&
        adGroupsMatch(approval.adGroupIds, result.destinationAdGroupIds) &&
        matchTypesMatch(approval.matchTypes, result.matchTypes));
};
exports.resultMatch = resultMatch;
const approvalsMatch = (approval, comparisonApproval) => {
    return (searchTermMatch(approval.searchTerm, comparisonApproval.searchTerm) &&
        campaignIdMatch(approval.campaignId, comparisonApproval.campaignId) &&
        adGroupsMatch(comparisonApproval.adGroupIds, approval.adGroupIds) &&
        targetingTypeMatch(approval.targetingType, comparisonApproval.targetingType) &&
        matchTypesMatch(comparisonApproval.matchTypes, approval.matchTypes));
};
exports.approvalsMatch = approvalsMatch;
const resultsWaitingApproval = (results, keywordApprovals) => {
    const resultsWithDestination = results && results.filter((result) => result.destinationCampaignId);
    if (!resultsWithDestination) {
        return [];
    }
    return resultsWithDestination.filter((result) => {
        const isApprovedOrRejected = keywordApprovals.find((approval) => {
            return (0, exports.resultMatch)(result, approval);
        });
        return !isApprovedOrRejected;
    });
};
exports.resultsWaitingApproval = resultsWaitingApproval;
const filterDuplicates = (results, keywordApprovals) => {
    const resultsWaitingForApproval = (0, exports.resultsWaitingApproval)(results, keywordApprovals);
    return (resultsWaitingForApproval &&
        resultsWaitingForApproval.filter((result, currentIndex) => {
            const index = resultsWaitingForApproval &&
                resultsWaitingForApproval.findIndex((currentResult) => {
                    const adTypeMatch = currentResult.adType === result.adType;
                    return (searchTermMatch(currentResult.searchTerm, result.searchTerm) &&
                        campaignIdMatch(currentResult.destinationCampaignId, result.destinationCampaignId) &&
                        targetingTypeMatch(currentResult.targetingType, result.targetingType) &&
                        adGroupsMatch(currentResult.destinationAdGroupIds, result.destinationAdGroupIds) &&
                        matchTypesMatch(currentResult.matchTypes, result.matchTypes) &&
                        adTypeMatch);
                });
            return index === currentIndex;
        }));
};
exports.filterDuplicates = filterDuplicates;
