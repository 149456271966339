"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGroupOperator = exports.getFieldLabel = exports.getOperatorLabel = void 0;
const rules_1 = require("../constants/rules");
const fields_1 = require("./rules/fields");
const getOperatorLabel = (field, rawOperator) => {
    var _a;
    return (_a = rules_1.OPERATORS.find((operator) => { var _a; return operator.type === ((_a = (0, fields_1.getField)(field)) === null || _a === void 0 ? void 0 : _a.type) && operator.value === rawOperator; })) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getOperatorLabel = getOperatorLabel;
const getFieldLabel = (field) => {
    var _a;
    return (_a = (0, fields_1.getField)(field)) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getFieldLabel = getFieldLabel;
const getGroupOperator = (rawOperator) => {
    var _a;
    return (_a = rules_1.GROUP_OPERATORS.find((operator) => operator.value === rawOperator)) === null || _a === void 0 ? void 0 : _a.label;
};
exports.getGroupOperator = getGroupOperator;
