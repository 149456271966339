"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function TableCell({ value, style, children, showTooltip = true, }) {
    const [tipOpen, setTipOpen] = (0, react_1.useState)(false);
    const cellRef = (0, react_1.useRef)(null);
    function toolTipTimer() {
        return setTimeout(toolTipOpen, 500);
    }
    function toolTipOpen() {
        if (!tipOpen) {
            setTipOpen(true);
        }
    }
    function toolTipClose() {
        if (tipOpen) {
            setTipOpen(false);
        }
    }
    return ((0, jsx_runtime_1.jsxs)("div", { ref: cellRef, onMouseEnter: () => toolTipTimer(), onMouseOut: () => toolTipClose(), className: `border-b border-neutral-40 group/item z-20 table-cell align-middle p-2 truncate max-w-[12.5rem] flex ${style} `, children: [tipOpen && showTooltip && ((0, jsx_runtime_1.jsx)("span", { className: `w-content group/edit absolute hidden h-[1.875rem] rounded bg-neutral-100 p-2 text-xs text-white group-hover/item:block group-hover/item:translate-y-[-2.415rem] 
          `, children: value })), children] }));
}
exports.default = TableCell;
