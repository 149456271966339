import { User } from '@fern/types';
import { useLoaderData } from 'react-router-dom';
import { client } from '../api';

interface LoaderData {
  users: User[];
}

export async function userListLoader(): Promise<LoaderData> {
  const users = (await client.get(`/users`)).data as User[];
  return { users };
}

export default function UserList() {
  const { users } = useLoaderData() as LoaderData;

  return (
    <>
      <div className="mb-2 mt-8 flex-auto">
        <h2>Users</h2>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-neutral-40">
                <thead className="bg-secondary-40">
                  <tr>
                    <th
                      scope="col"
                      className="py-4 pl-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-4 text-left text-sm font-semibold text-gray-900"
                    >
                      Email verified
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-40">
                  {users.map((user, index) => (
                    <tr
                      key={index}
                      className={index % 2 ? 'bg-secondary-20' : ''}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900">
                        <a href={`/users/${user.id}`}>
                          {user.firstName} {user.lastName}{' '}
                        </a>
                      </td>
                      <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                        {user.emailVerified ? 'YES' : 'NO'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
