"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showLoadMoreButton = exports.getResultsPage = exports.addToResults = exports.RESULTS_PAGE_FOUR = exports.RESULTS_PAGE_THREE = exports.RESULTS_PAGE_TWO = exports.RESULTS_PAGE_ONE = void 0;
exports.RESULTS_PAGE_ONE = 10;
exports.RESULTS_PAGE_TWO = 40;
exports.RESULTS_PAGE_THREE = 70;
exports.RESULTS_PAGE_FOUR = 100;
const addToResults = (stepId, runSteps, results) => {
    return runSteps.map((runStep) => {
        if (stepId === runStep.stepId) {
            const newResults = runStep.results
                ? [...runStep.results, ...results]
                : results;
            return Object.assign(Object.assign({}, runStep), { results: newResults });
        }
        return runStep;
    });
};
exports.addToResults = addToResults;
const getResultsPage = (resultsCount) => {
    if (resultsCount === exports.RESULTS_PAGE_ONE) {
        return 2;
    }
    if (resultsCount === exports.RESULTS_PAGE_TWO) {
        return 3;
    }
    if (resultsCount === exports.RESULTS_PAGE_THREE) {
        return 4;
    }
    throw Error(`No handler for results count: ${resultsCount}`);
};
exports.getResultsPage = getResultsPage;
const showLoadMoreButton = (resultsCount) => {
    if (resultsCount === exports.RESULTS_PAGE_ONE ||
        resultsCount === exports.RESULTS_PAGE_TWO ||
        resultsCount === exports.RESULTS_PAGE_THREE) {
        return true;
    }
    return false;
};
exports.showLoadMoreButton = showLoadMoreButton;
