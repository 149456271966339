"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXTENDED_PRESETS = exports.DEFAULT_PRESETS = exports.EXTENDED_ACTION_PRESETS = exports.ACTION_PRESETS = exports.SEARCH_TERMS_COLUMNS = exports.AD_GROUP_COLUMNS = exports.CAMPAIGN_COLUMNS = exports.TARGETS_COLUMNS = exports.SHARED_EXTENDED_COLUMNS = exports.SEARCH_TERMS_TABLE = exports.ALL_TABLES = exports.COLUMNS = void 0;
const actions_1 = require("./actions");
const rules_1 = require("./rules");
exports.COLUMNS = {
    dateFrom: { value: 'dateFrom', label: 'Date from' },
    dateTo: { value: 'dateTo', label: 'Date to' },
    campaign: { value: 'campaignName', label: 'Campaign' },
    adGroupName: { value: 'adGroupName', label: 'Ad group' },
    targeting: { value: 'text', label: 'Targeting' },
    // Targets
    currentBid: {
        value: 'currentBid',
        label: 'Bid',
        showCurrency: true,
    },
    newBid: {
        value: 'simulatedBid',
        label: 'New bid',
        showCurrency: true,
    },
    // Search Terms
    bid: { value: 'bid', label: 'Bid', showCurrency: true },
    status: { value: 'status', label: 'Status' },
    newStatus: { value: 'adjustedStatus', label: 'New status' },
    impressions: { value: 'impressions', label: 'Impressions' },
    clicks: { value: 'clicks', label: 'Clicks' },
    spend: { value: 'cost', label: 'Spend', showCurrency: true },
    sales: { value: 'sales', label: 'Sales', showCurrency: true },
    orders: { value: 'orders', label: 'Orders' },
    ctr: { value: 'ctr', label: 'CTR', showPercent: true },
    acos: { value: 'acos', label: 'ACOS', showPercent: true },
    roas: { value: 'roas', label: 'ROAS' },
    cr: { value: 'cr', label: 'CR', showPercent: true },
    cpc: { value: 'cpc', label: 'CPC', showCurrency: true },
    deliveryDays: { value: 'delivery_days', label: 'Delivery days' },
    asin: { value: 'asin', label: 'ASIN' },
    countryCode: { value: 'countryCode', label: 'Marketplace' },
    profileCrClicks: {
        value: 'profileCrClicks',
        label: 'Mktp CR Clicks',
    },
    profileCr: {
        value: 'profileCr',
        label: 'Mktp CR',
        showPercent: true,
    },
    averageDailyCost: {
        value: 'averageDailyCost',
        label: 'Avg Daily Spend',
        showCurrency: true,
    },
    budget: { value: 'budget', label: 'Budget', showCurrency: true },
    adjustedBudget: {
        value: 'adjustedBudget',
        label: 'Adjusted budget',
        showCurrency: true,
    },
    // Keyword columns
    source: { value: 'source', label: 'Source' },
    searchTerm: { value: 'searchTerm', label: 'New keyword' },
    destination: { value: 'destination', label: 'Destination' },
    matchTypes: { value: 'matchTypes', label: 'Match types' },
    targetingType: { value: 'targetingType', label: 'Targeting type' },
    mtdSpend: {
        value: 'profileActualSpendToDate',
        label: 'MTD Spend',
        showCurrency: true,
    },
    mtdSpendTarget: {
        value: 'profileExpectedSpendToDate',
        label: 'MTD Spend Target',
        showCurrency: true,
    },
    targetingText: { value: 'targetingText', label: 'Targeting text' },
    negateKeywords: {
        value: 'negateKeywords',
        label: 'Negate',
    },
};
exports.ALL_TABLES = [
    exports.COLUMNS.dateFrom,
    exports.COLUMNS.dateTo,
    exports.COLUMNS.countryCode,
    exports.COLUMNS.campaign,
];
exports.SEARCH_TERMS_TABLE = [
    exports.COLUMNS.countryCode,
    exports.COLUMNS.source,
    exports.COLUMNS.searchTerm,
    exports.COLUMNS.destination,
    exports.COLUMNS.negateKeywords,
];
exports.SHARED_EXTENDED_COLUMNS = [
    exports.COLUMNS.impressions,
    exports.COLUMNS.clicks,
    exports.COLUMNS.spend,
    exports.COLUMNS.sales,
    exports.COLUMNS.orders,
    exports.COLUMNS.ctr,
    exports.COLUMNS.acos,
    exports.COLUMNS.roas,
    exports.COLUMNS.cr,
    exports.COLUMNS.cpc,
    exports.COLUMNS.profileCrClicks,
    exports.COLUMNS.profileCr,
    exports.COLUMNS.mtdSpend,
    exports.COLUMNS.mtdSpendTarget,
    // COLUMNS.deliveryDays,
    // COLUMNS.asin,
];
// Data type columns
exports.TARGETS_COLUMNS = [
    exports.COLUMNS.adGroupName,
    exports.COLUMNS.targeting,
    exports.COLUMNS.currentBid,
];
exports.CAMPAIGN_COLUMNS = [exports.COLUMNS.status];
exports.AD_GROUP_COLUMNS = [exports.COLUMNS.adGroupName];
exports.SEARCH_TERMS_COLUMNS = [
    exports.COLUMNS.matchTypes,
    exports.COLUMNS.targetingType,
    exports.COLUMNS.targetingText,
    exports.COLUMNS.bid,
];
// Action columns
exports.ACTION_PRESETS = {
    [actions_1.CURRENT_BID]: [exports.COLUMNS.currentBid, exports.COLUMNS.newBid],
    cpc: [exports.COLUMNS.currentBid, exports.COLUMNS.newBid],
    [actions_1.CHANGE_STATUS]: [exports.COLUMNS.status, exports.COLUMNS.newStatus],
    [actions_1.CURRENT_BUDGET]: [
        exports.COLUMNS.averageDailyCost,
        exports.COLUMNS.budget,
        exports.COLUMNS.adjustedBudget,
    ],
};
// Only show these columns for selected action when table extended
exports.EXTENDED_ACTION_PRESETS = {
    [actions_1.CURRENT_BUDGET]: [],
    [actions_1.HARVEST_KEYWORDS]: [
        exports.COLUMNS.matchTypes,
        exports.COLUMNS.targetingType,
        exports.COLUMNS.currentBid,
    ],
};
// Regular table presets
exports.DEFAULT_PRESETS = {
    [rules_1.TARGETS]: [...exports.ALL_TABLES, ...exports.TARGETS_COLUMNS],
    [rules_1.CAMPAIGNS]: [...exports.ALL_TABLES, ...exports.CAMPAIGN_COLUMNS],
    [rules_1.AD_GROUPS]: [...exports.ALL_TABLES, ...exports.AD_GROUP_COLUMNS],
    [rules_1.SEARCH_TERMS]: [...exports.SEARCH_TERMS_TABLE],
};
// Extended table presets
exports.EXTENDED_PRESETS = {
    [rules_1.TARGETS]: [
        ...exports.DEFAULT_PRESETS[rules_1.TARGETS],
        ...exports.SHARED_EXTENDED_COLUMNS,
        // COLUMNS.status
    ],
    [rules_1.CAMPAIGNS]: [...exports.DEFAULT_PRESETS[rules_1.CAMPAIGNS], ...exports.SHARED_EXTENDED_COLUMNS],
    [rules_1.AD_GROUPS]: [
        ...exports.DEFAULT_PRESETS[rules_1.AD_GROUPS],
        ...exports.SHARED_EXTENDED_COLUMNS,
        exports.COLUMNS.currentBid,
    ],
    [rules_1.SEARCH_TERMS]: [
        ...[exports.COLUMNS.dateFrom, exports.COLUMNS.dateTo],
        ...exports.SEARCH_TERMS_TABLE,
        ...exports.SHARED_EXTENDED_COLUMNS,
        ...exports.SEARCH_TERMS_COLUMNS,
    ],
};
