"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const actions_1 = require("../constants/actions");
const actions_2 = require("../helpers/actions");
function useAction(action, dataType) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const options = actions_1.BID_OPTIONS.concat(actions_1.BUDGET_OPTIONS);
    const bidAction = action;
    const statusAction = action;
    const isIncrease = ((_a = bidAction === null || bidAction === void 0 ? void 0 : bidAction.properties) === null || _a === void 0 ? void 0 : _a.value) > 0;
    const conditionLabel = isIncrease ? 'Increase' : 'Decrease';
    const isPauseOrEnable = (action === null || action === void 0 ? void 0 : action.type) === actions_1.CHANGE_STATUS;
    const isEmail = (action === null || action === void 0 ? void 0 : action.type) === actions_1.SEND_EMAIL;
    const isSlack = (action === null || action === void 0 ? void 0 : action.type) === actions_1.SEND_SLACK;
    const isBidOrBudget = (action === null || action === void 0 ? void 0 : action.type) === actions_1.UPDATE_BID || (action === null || action === void 0 ? void 0 : action.type) === actions_1.UPDATE_BUDGET;
    const isKeywordHarvesting = (action === null || action === void 0 ? void 0 : action.type) === actions_1.HARVEST_KEYWORDS;
    const metricLabel = (_b = options.find((option) => { var _a; return option.value === ((_a = bidAction.properties) === null || _a === void 0 ? void 0 : _a.metric); })) === null || _b === void 0 ? void 0 : _b.label;
    // Originally pause/enable actions always matched datatype
    // Templates step summary can now match any pause/enable option ie ads
    const pauseEnablelabel = (_c = actions_1.PAUSE_ENABLE_OPTIONS.find((option) => option.value === dataType ||
        (statusAction.properties &&
            option.value === statusAction.properties.entityType))) === null || _c === void 0 ? void 0 : _c.label.toLowerCase();
    const bidBudgetValue = isIncrease && ((_d = bidAction === null || bidAction === void 0 ? void 0 : bidAction.properties) === null || _d === void 0 ? void 0 : _d.value)
        ? (_f = (_e = bidAction === null || bidAction === void 0 ? void 0 : bidAction.properties) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.toString()
        : (_h = (_g = bidAction === null || bidAction === void 0 ? void 0 : bidAction.properties) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.toString().substring(1);
    const currentActionTab = (0, actions_2.getActionTab)(isPauseOrEnable, (_j = statusAction.properties) === null || _j === void 0 ? void 0 : _j.status, action === null || action === void 0 ? void 0 : action.type);
    return {
        isPauseOrEnable,
        isBidOrBudget,
        metricLabel,
        isEmail,
        conditionLabel,
        pauseEnablelabel,
        isKeywordHarvesting,
        bidBudgetValue,
        currentActionTab,
        isSlack,
    };
}
exports.default = useAction;
