"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const rules_1 = require("../../../constants/rules");
const keywordApprovals_1 = require("./keywordApprovals");
function useSortApprovals(step, keywordApprovals) {
    const [actionedResults, setActionedResults] = (0, react_1.useState)([]);
    const [resultsToBeActioned, setResultsToBeActioned] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        const alreadyActioned = step.results &&
            step.results.filter((result) => {
                return (keywordApprovals &&
                    keywordApprovals.find((approval) => {
                        return ((0, keywordApprovals_1.resultMatch)(result, approval) &&
                            (approval.status === rules_1.APPROVED || approval.status === rules_1.REJECTED));
                    }));
            });
        const unactioned = step.results &&
            step.results.filter((result) => {
                const alreadyActioned = keywordApprovals &&
                    keywordApprovals.find((approval) => {
                        return ((0, keywordApprovals_1.resultMatch)(result, approval) &&
                            (approval.status === rules_1.APPROVED || approval.status === rules_1.REJECTED));
                    });
                if (alreadyActioned) {
                    return false;
                }
                return true;
            });
        // No campaign links at bottom of list
        const sortedUnactionedResults = unactioned &&
            unactioned.sort((a, b) => {
                if (a.destinationCampaignId && !b.destinationCampaignId) {
                    return -1;
                }
                if (!a.destinationCampaignId && b.destinationCampaignId) {
                    return 1;
                }
                return 0;
            });
        setActionedResults(alreadyActioned);
        setResultsToBeActioned(sortedUnactionedResults);
    }, [keywordApprovals]);
    return { actionedResults, resultsToBeActioned };
}
exports.default = useSortApprovals;
