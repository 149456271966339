"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInternationalNumber = exports.browserLocale = void 0;
const browserLocale = () => {
    var _a;
    return ((_a = navigator.languages) === null || _a === void 0 ? void 0 : _a.length)
        ? navigator.languages[0]
        : navigator.language || 'en-US';
};
exports.browserLocale = browserLocale;
const getInternationalNumber = (value, currency, style, minFractionDigits, maxFractionDigits) => {
    return new Intl.NumberFormat((0, exports.browserLocale)(), {
        style: style,
        currency: currency,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
    }).format(value);
};
exports.getInternationalNumber = getInternationalNumber;
