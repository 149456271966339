"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAUSE_ENABLE_OPTIONS = exports.BUFFER_OPTIONS = exports.CONDITION_OPTIONS = exports.PRICE_OPTIONS = exports.BUDGET_OPTIONS = exports.BID_OPTIONS = exports.ACTION_DEFAULTS = exports.ACTION_GROUPS = exports.CURRENT_BUDGET = exports.CURRENT_PRICE = exports.CURRENT_BID = exports.HARVEST_KEYWORDS = exports.UPDATE_BUDGET = exports.CHANGE_STATUS = exports.SEND_SLACK = exports.SEND_EMAIL = exports.UPDATE_BID = void 0;
const icons_1 = require("@fern/icons");
const rules_1 = require("./rules");
exports.UPDATE_BID = 'updateBid';
exports.SEND_EMAIL = 'sendEmail';
exports.SEND_SLACK = 'sendSlack';
exports.CHANGE_STATUS = 'changeStatus';
exports.UPDATE_BUDGET = 'updateBudget';
exports.HARVEST_KEYWORDS = 'harvestKeywords';
exports.CURRENT_BID = 'currentBid';
exports.CURRENT_PRICE = 'currentPrice';
exports.CURRENT_BUDGET = 'currentBudget';
exports.ACTION_GROUPS = [
    {
        label: 'ADVERTISING',
        actions: [
            {
                type: exports.UPDATE_BUDGET,
                label: 'Update budget',
                enabled: 'all',
                comingSoon: false,
                icon: icons_1.BudgetIcon,
                largeIcon: icons_1.LargeBudgetIcon,
                availableFor: [rules_1.CAMPAIGNS],
                hide: false,
            },
            {
                type: exports.UPDATE_BID,
                label: 'Update target bid',
                enabled: 'all',
                comingSoon: false,
                largeIcon: icons_1.LargePercentIcon,
                icon: icons_1.PercentIcon,
                availableFor: [rules_1.TARGETS],
            },
            {
                type: 'paused',
                label: 'Pause',
                enabled: 'internal',
                comingSoon: true,
                icon: icons_1.PauseIcon,
                largeIcon: icons_1.LargePauseIcon,
                availableFor: [rules_1.TARGETS, rules_1.CAMPAIGNS],
            },
            {
                type: 'enabled',
                label: 'Enable',
                enabled: 'internal',
                comingSoon: true,
                icon: icons_1.PlaySquareIcon,
                largeIcon: icons_1.LargePlaySquareIcon,
                availableFor: [rules_1.TARGETS, rules_1.CAMPAIGNS],
            },
            {
                type: exports.HARVEST_KEYWORDS,
                label: 'Harvest keywords',
                enabled: 'all',
                icon: icons_1.PlusSquareIcon,
                largeIcon: icons_1.LargePlusSquareIcon,
                comingSoon: false,
                availableFor: [rules_1.SEARCH_TERMS],
            },
        ],
    },
    {
        label: 'MONITORING',
        actions: [
            {
                type: exports.SEND_EMAIL,
                label: 'Send email alert',
                enabled: 'all',
                icon: icons_1.SendEmailIcon,
                largeIcon: icons_1.LargeSendEmailIcon,
                comingSoon: false,
                availableFor: [rules_1.TARGETS, rules_1.CAMPAIGNS, rules_1.SEARCH_TERMS, rules_1.PRODUCT_SIGNALS],
            },
            {
                type: exports.SEND_SLACK,
                label: 'Send message to slack',
                enabled: 'internal',
                icon: icons_1.SlackIcon,
                largeIcon: icons_1.LargeSlackIcon,
                comingSoon: true,
                availableFor: [rules_1.PRODUCT_SIGNALS],
                hide: true,
            },
        ],
    },
];
const bidDefault = {
    type: exports.UPDATE_BID,
    properties: {
        metric: exports.CURRENT_BID,
        value: undefined,
        buffer: 1,
    },
};
const budgetDefault = {
    type: exports.UPDATE_BUDGET,
    properties: {
        metric: exports.CURRENT_BUDGET,
        value: undefined,
        buffer: 1,
    },
};
const emailDefault = {
    type: exports.SEND_EMAIL,
};
const pauseEnableDefault = {
    type: exports.CHANGE_STATUS,
    properties: {
        entityType: 'campaigns',
        status: 'paused',
    },
};
const harvestKeywordDefault = {
    type: exports.HARVEST_KEYWORDS,
    properties: {
        sendEmail: false,
    },
};
const slackDefault = {
    type: exports.SEND_SLACK,
    properties: {
        channelId: '',
        channelName: '',
    },
};
exports.ACTION_DEFAULTS = {
    [exports.UPDATE_BID]: bidDefault,
    [exports.UPDATE_BUDGET]: budgetDefault,
    [exports.SEND_EMAIL]: emailDefault,
    [exports.CHANGE_STATUS]: pauseEnableDefault,
    [exports.HARVEST_KEYWORDS]: harvestKeywordDefault,
    [exports.SEND_SLACK]: slackDefault,
};
exports.BID_OPTIONS = [
    { value: exports.CURRENT_BID, label: 'Current bid' },
    { value: 'cpc', label: 'CPC' },
];
exports.BUDGET_OPTIONS = [
    { value: exports.CURRENT_BUDGET, label: 'Current budget' },
];
exports.PRICE_OPTIONS = [{ value: exports.CURRENT_PRICE, label: 'Current price' }];
exports.CONDITION_OPTIONS = [
    { value: 'increase', label: 'Increase' },
    { value: 'decrease', label: 'Decrease' },
];
exports.BUFFER_OPTIONS = [
    { value: '1', label: '1 day' },
    { value: '2', label: '2 days' },
    { value: '3', label: '3 days' },
    { value: '4', label: '4 days' },
    { value: '5', label: '5 days' },
    { value: '6', label: '6 days' },
    { value: '7', label: '7 days' },
];
exports.PAUSE_ENABLE_OPTIONS = [
    { label: 'Targets', value: rules_1.TARGETS },
    { label: 'Campaigns', value: rules_1.CAMPAIGNS },
    { label: 'Ad groups', value: rules_1.AD_GROUPS },
    { label: 'Search terms', value: rules_1.SEARCH_TERMS },
    { label: 'Ads', value: rules_1.ADS },
];
